import React, { useEffect, useRef, useState, memo } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'

import {
  GET_ALL_GA_JOB,
  CANCEL_JOB_BY_ID,
  TRIGGER_JOB_BY_ID,
  GET_CONFIGURATION_BY_TYPE,
  UPDATE_CONFIGURATION_BY_ID,
  GET_GA_JOB_DETAIL_TO_DOWNLOAD,
} from '../../../../queries/adminPortal'
import Loading from '../../../../components/loading'
import { useSession } from '../../../../helpers/checkSession'
import { tableHeaderJobData } from './headers'
import { ActionJobItem, Variables, VariablesGAList } from './interface'
// import UserListDialog from './UserListDialog'
import { CODE_TIMEOUT } from '../../../../constants/statusCode'
import Moment from 'react-moment'
import SearchInput from '../../../principal-account-management/components/searchInput'
import IconDot from '../../../../assets/images/icon-dot.svg'
import IconApprove from '../../../../assets/images/icon-approve.svg'
import IconInformation from '../../../../assets/images/icon-information.svg'
interface Props {
  setIsRefetchList: (refetchUser: boolean) => void
  isRefetchList: boolean
}

import classNames from 'classnames'
import DateFilterPicker from '../../../developer-portal/table/activities-log/component/dateFilterPicker'
import moment from 'moment'
import DialogBasic from '../../../developer-portal/dialog/dialogBasic'
import { notify } from '../../../../components/toastify'
import { MESSAGES, STATUS } from '../../../../constants/messages'
import DetailItemDialog from './detailItemDialog'
import SingleRowActionBase from '../../../../components/partials/singleRowActionBase'
import CheckBoxBase from '../../../../components/partials/toggleBase'
import ReactTooltip from 'react-tooltip'
import ButtonBase from '../../../../components/partials/button'
import DropdownBase from '../../../../components/partials/dropDown'
import { EMAIL_JOB_STATUS } from '../../../../constants/common'

const GAListTable: React.FC<Props> = ({ setIsRefetchList, isRefetchList }) => {
  const menuRef = useRef<HTMLDivElement>(null)
  const subjectFilterRef = useRef<HTMLDivElement>(null)
  const subjectCheckboxRef = useRef<HTMLDivElement>(null)
  const [disableDownload, setDisableDownload] = useState<boolean>(false)
  const [isTableGaScroll, setIsTableGaScroll] = useState<boolean>(false)
  const [isFocusSearchEmailText, setIsFocusEmailText] = useState<boolean>(false)
  const [isFocusApplicationNumber, setIsFocusApplicationNumber] = useState<boolean>(false)
  const [searchObject, setSearchObject] = useState<Variables>()
  const [minDateFilter, setMinDateFilter] = useState<Date>()
  const [maxDateFilter, setMaxDateFilter] = useState<Date>()
  const [isOpenModalCancelJob, setIsOpenModalCancelJob] = useState<boolean>(false)
  const [isOpenModalTriggerJob, setIsOpenModalTriggerJob] = useState<boolean>(false)
  const [isOpenModalViewDetailJob, setIsOpenModalViewDetailJob] = useState<boolean>(false)
  const [currentJobItemSelected, setCurrentJobItemSelected] = useState<any>()
  const [showAction, setShowAction] = useState<ActionJobItem>({
    jobId: null,
    isShowAction: false,
  })
  const [serviceConfiguration, setServiceConfiguration] = useState({
    value: '',
    key: '',
    configId: '',
  })

  const [smsProviderConfiguration, setSmsProviderConfiguration] = useState({
    value: '',
    key: '',
    configId: '',
  })

  const { timeOutSession } = useSession()

  const {
    data: getGAJob,
    loading: getGaLoading,
    refetch: getGAListRefetch,
    error: getGAListError,
  } = useQuery(GET_ALL_GA_JOB, {
    fetchPolicy: 'no-cache',
    // fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: 200,
      page: 1,
    },
  } as VariablesGAList)

  const { data: configurationByTypeData, refetch: getSMSProviderConfigRefetch } = useQuery(
    GET_CONFIGURATION_BY_TYPE,
    {
      fetchPolicy: 'no-cache',
      variables: {
        type: 'SMS_SETTING',
      },
    },
  )

  const [getConfigurationByType] = useLazyQuery(GET_CONFIGURATION_BY_TYPE)
  const [getGAJobDetailToDownload] = useLazyQuery(GET_GA_JOB_DETAIL_TO_DOWNLOAD)

  const [cancelJobById, { error: errorCancelJobById }] = useMutation(CANCEL_JOB_BY_ID)
  const [triggerJobById, { error: errorTriggerJobById }] = useMutation(TRIGGER_JOB_BY_ID)
  const [updateConfigurationById] = useMutation(UPDATE_CONFIGURATION_BY_ID)

  const pendingJobStatus = ['PENDING', 'START_STREAM']
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false)

  const handleGetServiceConfiguration = (newConfiguration?: any) => {
    if (newConfiguration) {
      setServiceConfiguration(newConfiguration)
    } else {
      getConfigurationByType({ variables: { type: 'SERVICE_SETTING' } }).then((response) => {
        if (response?.data?.getConfigurationByType?.length > 0) {
          const config = response?.data?.getConfigurationByType.find(
            (item: { key: string }) => item.key === 'IS_STOP_JOB',
          )
          if (config) setServiceConfiguration(config)
        }
      })
    }
  }

  useEffect(() => {
    if (configurationByTypeData && configurationByTypeData.getConfigurationByType.length > 0) {
      const config = configurationByTypeData?.getConfigurationByType?.find(
        (item: { key: string }) => item.key === 'SMS_PROVIDER',
      )
      setSmsProviderConfiguration({
        value: config?.value || 'vonage',
        configId: config?.configId || '',
        key: 'SMS_PROVIDER',
      })
    } else {
      setSmsProviderConfiguration({
        value: 'vonage',
        configId: '',
        key: 'SMS_PROVIDER',
      })
    }
  }, [configurationByTypeData])

  useEffect(() => {
    if (!getGAJob) {
      handleGetServiceConfiguration()
      getGAListRefetch()
    }
  }, [getGAJob])

  const handleSearchEmailAndPhone = (value: string) => {
    setSearchObject((state) => {
      return { ...state, searchValue: value.trim(), limit: 200, page: 1 }
    })
  }
  const handleSearchApplicationNumber = (value: string) => {
    setSearchObject((state) => {
      return { ...state, applicationNumber: value.trim(), limit: 200, page: 1 }
    })
  }
  useEffect(() => {
    getGAListRefetch(searchObject)
  }, [searchObject])

  const onHandleClickShowAction = (item: any) => {
    if (showAction.jobId == item.jobId) {
      return
    }
    setShowAction({
      jobId: item.jobId,
      isShowAction: true,
    })
    setCurrentJobItemSelected({ ...item })
  }

  const onHandleClickCancelAction = () => {
    setIsOpenModalCancelJob(true)
  }
  const onHandleConfirmCancelAction = async () => {
    if (currentJobItemSelected && pendingJobStatus.includes(currentJobItemSelected.status)) {
      const dataRes = await cancelJobById({
        variables: {
          jobId: currentJobItemSelected?.jobId,
        },
      })
      if (dataRes) {
        notify(MESSAGES.SUCCESS.S_GENERAL_SUCCESS, STATUS.SUCCESS)
        getGAListRefetch({ limit: 200, page: 1 })
      }
      handleCloseConfirmInfoDialog()
    } else {
      handleCloseConfirmInfoDialog()
    }
  }
  const onHandleClickTriggerJobAction = () => {
    setIsOpenModalTriggerJob(true)
  }
  const onHandleClickViewDetailJobAction = () => {
    setIsOpenModalViewDetailJob(true)
  }
  const onHandleConfirmTriggerJobAction = async () => {
    if (currentJobItemSelected && pendingJobStatus.includes(currentJobItemSelected.status)) {
      notify('Requested', STATUS.INFO)
      const dataRes = await triggerJobById({
        variables: {
          jobId: currentJobItemSelected?.jobId,
        },
      })
      if (dataRes) {
        notify(MESSAGES.SUCCESS.S_GENERAL_SUCCESS, STATUS.SUCCESS)
      }
      getGAListRefetch({ limit: 200, page: 1 })
      handleCloseConfirmInfoDialog()
    } else {
      handleCloseConfirmInfoDialog()
    }
  }

  const handleCloseConfirmInfoDialog = () => {
    setIsOpenModalViewDetailJob(false)
    setIsOpenModalCancelJob(false)
    setIsOpenModalTriggerJob(false)
  }

  useEffect(() => {
    if (errorCancelJobById?.message || errorTriggerJobById?.message) {
      notify(errorCancelJobById?.message ?? errorTriggerJobById?.message, STATUS.ERROR)
    }
  }, [errorCancelJobById, errorTriggerJobById])

  const startDateChange = (value: Date) => {
    setMinDateFilter(value)
    const dateFrom = value ? moment(value).format('YYYY-MM-DD') : ''
    setSearchObject((state) => {
      return { ...state, dateFrom, limit: 200, page: 1 }
    })
  }
  const endDateChange = (value: Date) => {
    setMaxDateFilter(value)
    const dateTo = value ? moment(value).format('YYYY-MM-DD') : ''
    setSearchObject((state) => {
      return { ...state, dateTo, limit: 200, page: 1 }
    })
  }
  useEffect(() => {
    if (isRefetchList) {
      getGAListRefetch({
        limit: 200,
        page: 1,
      })
      setIsRefetchList(false)
    }
  }, [isRefetchList])

  useEffect(() => {
    const handleClickOutSide = (e: any) => {
      if (menuRef && !menuRef.current?.contains(e.target)) {
        setShowAction({ jobId: null, isShowAction: false })
      }
    }
    document.addEventListener('mousedown', handleClickOutSide)
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide)
    }
  }, [showAction])

  useEffect(() => {
    const handleClickOutSideFilter = (e: any) => {
      if (
        subjectFilterRef &&
        !subjectFilterRef.current?.contains(e.target) &&
        subjectCheckboxRef &&
        !subjectCheckboxRef.current?.contains(e.target) &&
        e.target.id !== 'filter-ref'
      ) {
        setIsOpenFilter(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutSideFilter)
    return () => {
      document.removeEventListener('mousedown', handleClickOutSideFilter)
    }
  }, [isOpenFilter])

  useEffect(() => {
    const clientHeight = document.querySelector('#cus-scrollbar')?.clientHeight
    if (document.querySelector('#cus-scrollbar')?.clientHeight && clientHeight) {
      setIsTableGaScroll(clientHeight >= 588)
    }
  }, [document.querySelector('#cus-scrollbar')?.clientHeight, getGAJob])

  useEffect(() => {
    if (getGAListError?.graphQLErrors[0]?.extensions?.code === CODE_TIMEOUT) {
      timeOutSession()
    }
  }, [getGAListError])

  const handleButtonStartOrStop = () => {
    updateConfigurationById({
      variables: {
        configurationId: serviceConfiguration.configId,
        data: {
          value: (!(serviceConfiguration.value.toLowerCase() === 'true')).toString(),
        },
      },
    }).then((response) => {
      handleGetServiceConfiguration(response.data.updateConfigurationById)
      if (response.data.updateConfigurationById.value.toLowerCase() === 'true') {
        //  Restart (isStop === true)
        notify(MESSAGES.SUCCESS.S_RESTART_SENDING_EMAIL_JOB, STATUS.SUCCESS)
      } else {
        notify(MESSAGES.SUCCESS.S_STOP_SENDING_EMAIL_JOB, STATUS.SUCCESS)
      }
    })
  }

  const formatCSVValue = (value: string) => {
    if (!value) {
      return ''
    }
    if (typeof value === 'string' && value.includes(',')) {
      return `"${value.replace(/"/g, '""')}"`
    }

    return value
  }

  const onHandleToggleChangeStatus = async () => {
    if (smsProviderConfiguration.configId) {
      updateConfigurationById({
        variables: {
          configurationId: smsProviderConfiguration.configId,
          data: {
            value: smsProviderConfiguration.value === 'ozeki' ? 'vonage' : 'ozeki',
          },
        },
      }).then((response) => {
        getSMSProviderConfigRefetch()
      })
    } else {
      notify('SMS Provider not setting, use VONAGE as default provider', STATUS.ERROR)
    }
  }

  const handleButtonDownload = async () => {
    setDisableDownload(true)
    const response = await getGAJobDetailToDownload({
      variables: searchObject,
    })

    const data = response?.data?.getGAJobDetailToDownload

    const objectKeyCSV = [
      'jobId',
      'applicationNumber',
      'email',
      'phoneNumber',
      'stageCompleted',
      'stageDate',
      'expiryDate',
      'jobLastRunTime',
      'message',
      'status',
      'sendingEmailSubject',
      'emailTemplateURL',
      'textTemplateURL',
      'jobName',
      'metaData',
    ]
    let csvContent =
      'Job ID,Application Number,Email,Phone Number,Stage Completed,Stage Date,Job Last Run Date,Message,Status,Sending Email Subject,Email Template URL,Text Template URL,Job Name,Metadata\n'

    data.map((item: any) => {
      let row = ''
      objectKeyCSV.forEach((key: string) => {
        if (key === 'metaData') {
          row += formatCSVValue(item['messageMail'][key])
          row += '\n'
        } else {
          if (key === 'subject' || key === 'content') {
            row += formatCSVValue(item['messageMail'][key])
          } else if (key === 'message' && item['gaJobLog'][0]) {
            row += formatCSVValue(item['gaJobLog'][0][key])
          } else {
            row += formatCSVValue(item[key])
          }
          row += ','
        }
      })
      csvContent += row
    })
    // Create a Blob with the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

    // Create a temporary URL for the Blob
    const url = URL.createObjectURL(blob)

    // Create a download link and initiate the file download
    const downloadLink = document.createElement('a')
    downloadLink.href = url
    downloadLink.download = 'data.csv'
    downloadLink.click()

    setDisableDownload(false)
  }

  const getStatusValue = (val: any) => {
    if (val.name === 'ALL') {
      setSearchObject((state) => {
        return { ...state, status: null, limit: 200, page: 1 }
      })
    } else {
      setSearchObject((state) => {
        return { ...state, status: val.name, limit: 200, page: 1 }
      })
    }
  }

  return (
    <div>
      <div className="flex justify-between mb-3">
        <p className="pt-2 text-headline5A text-neutral-1">System Job Data</p>
        <div className="flex justify-end space-x-5">
          <ButtonBase
            className="disabled:bg-primary-shade3"
            bgHover="Download detail email job csv"
            onClick={handleButtonDownload}
            disabled={disableDownload}
          >
            Download CSV
          </ButtonBase>
          <div>
            <div className="flex">
              <span
                className={
                  'flex min-w-[60px] w-full font-montserrat font-medium text-base text-primary-50'
                }
              >
                {smsProviderConfiguration.value === 'ozeki' ? 'Ozeki' : 'Vonage'}
                <a
                  data-tip
                  id="sms-provider-tool-tip"
                  data-for="sms-provider-tool-tip"
                  className="pl-1 flex"
                >
                  <img src={IconInformation} alt="Icon-Information" className="w-[18px]" />
                </a>
                <ReactTooltip id="sms-provider-tool-tip" type="info" effect="solid">
                  <span>
                    {smsProviderConfiguration.value === 'ozeki'
                      ? 'Using Ozeki as default provider'
                      : 'Using Vonage as default provider'}
                  </span>
                </ReactTooltip>
              </span>
            </div>
            <div>
              <CheckBoxBase
                value={smsProviderConfiguration.value === 'ozeki'}
                handleToggle={onHandleToggleChangeStatus}
              />
            </div>
          </div>
          <div>
            <div className="flex">
              <span
                className={
                  'flex min-w-[60px] w-full font-montserrat font-medium text-base text-primary-50'
                }
              >
                {serviceConfiguration.value.toLowerCase() === 'true' ? 'Start' : 'Stop'}
                <a
                  data-tip
                  id="job-service-tool-tip"
                  data-for="job-service-tool-tip"
                  className="pl-1 flex"
                >
                  <img src={IconInformation} alt="Icon-Information" className="w-[18px]" />
                </a>
                <ReactTooltip id="job-service-tool-tip" type="info" effect="solid">
                  <span>
                    {serviceConfiguration.value.toLowerCase() === 'true'
                      ? 'Start sending email job'
                      : 'Stop sending email job'}
                  </span>
                </ReactTooltip>
              </span>
            </div>
            <div>
              <CheckBoxBase
                value={serviceConfiguration.value.toLowerCase() === 'true'}
                handleToggle={handleButtonStartOrStop}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full h-[112px] mb-5 grid grid-cols-3 gap-2">
        <DateFilterPicker setCallBackDateChange={startDateChange} maxDate={maxDateFilter} />
        <DateFilterPicker setCallBackDateChange={endDateChange} minDate={minDateFilter} />
        <DropdownBase
          options={EMAIL_JOB_STATUS.concat({ id: 'ALL', name: 'ALL' })}
          handleOptions={getStatusValue}
          initialValue={'ALL'}
        />
        <div
          className={classNames('rounded-xl border-[2px]  w-full', {
            'border-neutral-3': isFocusSearchEmailText,
            'border-neutral-5': !isFocusSearchEmailText,
          })}
        >
          <SearchInput
            classInput="border-0 w-full"
            setIsFocus={setIsFocusEmailText}
            handleSearch={handleSearchEmailAndPhone}
            placehoderText="Search box email or text no"
            isHandleSearch={false}
          />
        </div>
        <div
          className={classNames('rounded-xl border-[2px]  w-full', {
            'border-neutral-3': isFocusApplicationNumber,
            'border-neutral-5': !isFocusApplicationNumber,
          })}
        >
          <SearchInput
            classInput="border-0 w-full"
            setIsFocus={setIsFocusApplicationNumber}
            handleSearch={handleSearchApplicationNumber}
            placehoderText="Search application number"
            isHandleSearch={false}
          />
        </div>
      </div>
      <div className="z-10 w-full border-2 border-neutral-6 bg-neutral-8 rounded-2xl">
        <ul
          className={`flex bg-primary-shade4 px-6 py-8 justify-between rounded-t-[14px] ${
            isTableGaScroll ? 'pr-20' : ''
          }`}
        >
          {tableHeaderJobData.map((item, idx) => (
            <li className={`${item.minW} relative flex`} key={idx}>
              <span className={`font-montserrat font-bold text-base text-neutral-2`}>
                {item.label}
              </span>
            </li>
          ))}
        </ul>
        {getGaLoading && <Loading className="relative py-6" height={30} width={30} />}
        {!getGAJob?.getAllGaJob?.items?.length && !getGaLoading && (
          <p className="py-6 text-center text-body1 text-neutral-4">No data available.</p>
        )}

        {getGAJob?.getAllGaJob?.items?.length > 0 && !getGaLoading && (
          <div
            id="cus-scrollbar"
            className={`cus-scrollbar overflow-y-auto mt-5 mb-3 ${
              isTableGaScroll ? 'mr-2 overflow-y-auto' : 'mr-0 overflow-y-visible'
            }`}
          >
            <div className={`${isTableGaScroll ? 'pr-2' : 'pr-0'} max-h-[588px]`}>
              {getGAJob?.getAllGaJob?.items?.map((item: any, idx: number) => (
                <ul
                  className="flex justify-between px-6 py-3 mb-3 text-base font-medium font-montserrat text-neutral-3 hover:bg-neutral-7 hover:text-neutral-1"
                  key={idx}
                >
                  <li
                    className={tableHeaderJobData[0].minWRow}
                    style={{ maxWidth: tableHeaderJobData[0].maxWidth }}
                  >
                    {item.jobId}
                  </li>
                  <li
                    className={tableHeaderJobData[1].minWRow}
                    style={{ maxWidth: tableHeaderJobData[1].maxWidth }}
                  >
                    {item.jobName}
                  </li>
                  <li
                    className={tableHeaderJobData[2].minWRow}
                    style={{ maxWidth: tableHeaderJobData[2].maxWidth }}
                  >
                    {item.stageDate && <Moment format="MM/DD/YYYY">{item.stageDate}</Moment>}
                  </li>
                  <li
                    className={tableHeaderJobData[3].minWRow}
                    style={{ maxWidth: tableHeaderJobData[3].maxWidth }}
                  >
                    {item?.stageCompleted}
                  </li>
                  <li
                    className={tableHeaderJobData[4].minWRow}
                    style={{ maxWidth: tableHeaderJobData[4].maxWidth }}
                  >
                    {item.status}
                  </li>
                  <li
                    className={tableHeaderJobData[5].minWRow}
                    style={{ maxWidth: tableHeaderJobData[5].maxWidth }}
                  >
                    {item.applicationNumber}
                  </li>
                  <li
                    className={tableHeaderJobData[6].minWRow}
                    style={{ maxWidth: tableHeaderJobData[6].maxWidth }}
                  >
                    {item.email}
                  </li>
                  <li
                    className={tableHeaderJobData[7].minWRow}
                    style={{ maxWidth: tableHeaderJobData[7].maxWidth }}
                  >
                    {item.phoneNumber}
                  </li>
                  <button
                    className="min-w-[24px]  mb-auto"
                    onClick={() => onHandleClickShowAction(item)}
                  >
                    <div className="relative ">
                      <img src={IconDot} alt="Icon-Dot" className="" />
                      {showAction?.jobId === item.jobId && showAction.isShowAction && (
                        <div
                          className={`absolute right-[2px] h-auto p-3 border shadow-2xl w-60 border-neutral-7 bg-neutral-8 rounded-xl 'z-10'`}
                          ref={menuRef}
                        >
                          <SingleRowActionBase
                            handleAction={onHandleClickViewDetailJobAction}
                            icon={IconInformation}
                            action="ViewDetail"
                            txt="View Detail"
                          />
                          <SingleRowActionBase
                            handleAction={onHandleClickTriggerJobAction}
                            icon={IconApprove}
                            action="TriggerJob"
                            txt="Trigger Job"
                            block={!pendingJobStatus.includes(item.status)}
                          />
                          <hr className="my-1 border-t-neutral-7" />
                          <SingleRowActionBase
                            handleAction={onHandleClickCancelAction}
                            action="delete"
                            block={!pendingJobStatus.includes(item.status)}
                            txt="Cancel"
                          />
                        </div>
                      )}
                    </div>
                  </button>
                </ul>
              ))}
            </div>
          </div>
        )}
        {isOpenModalViewDetailJob && (
          <DetailItemDialog
            key="detailItemDialog"
            modalIsOpen={true}
            handleCloseDialog={handleCloseConfirmInfoDialog}
            jobData={currentJobItemSelected}
          />
        )}

        <DialogBasic
          modalIsOpen={isOpenModalCancelJob}
          title="Cancel Job"
          confirmation="Are you sure you want to cancel this job?"
          action="Confirm"
          cancel="No"
          handleCloseDialog={handleCloseConfirmInfoDialog}
          handleAction={onHandleConfirmCancelAction}
        />
        <DialogBasic
          modalIsOpen={isOpenModalTriggerJob}
          title="Trigger Job"
          confirmation="Are you sure you want to trigger this job?"
          action="Trigger"
          handleCloseDialog={handleCloseConfirmInfoDialog}
          handleAction={onHandleConfirmTriggerJobAction}
        />
      </div>
    </div>
  )
}

export default memo(GAListTable)
